<template>
  <div>
    <CRow>
      <FlashMessage :position="'right top'" style="z-index: 2000;"></FlashMessage>
      <CCard class="col-xl-12 padding-0">
        <CCardHeader>
            <CButton color="primary" class="btn-block col-xl-2" @click="addData()"><v-icon name="pencil-alt"/> Add Position</CButton>
        </CCardHeader>
        <CCardBody class="" style="text-align: center;">
            <CSpinner
                v-if="isLoading"
                color="info"
                style="width:3rem;height:3rem;"
            />
            <span v-if="!isLoading && this.position.length < 1">Data Belum Ada</span>
            <CDataTable
                v-if="this.position.length > 0"
                :hover="true"
                :striped="true"
                :border="true"
                :small="true"
                :fixed="true"
                :sorter="true"
                :items="this.position"
                :fields="fields"
                style="text-align: center;"
                tableFilter
                itemsPerPageSelect
                :itemsPerPage="this.itemperpage"
                :pagination="{ doubleArrows: true, align: 'end'}"
            >
            <template v-slot:number="data">
                <td>
                    {{data.index+1}}
                </td>
            </template>
            <template v-slot:actions="data">
                <td>
                  <CDropdown
                    color="secondary"
                    placement="bottom"
                  >
                    <template #toggler>
                      <CButton :color="data.item.is_active?'secondary':'danger'" class="btn-sm">
                        <v-icon name="caret-down"/>
                      </CButton>
                    </template>
                    <CDropdownItem @click="viewData(data.item.uuid)">
                      <v-icon name="eye" class="mr-1"/>View
                    </CDropdownItem>
                    <CDropdownItem @click="editData(data.item.uuid)">
                      <v-icon name="pencil-alt" class="mr-1 v-mid"/> Edit
                    </CDropdownItem>
                    <CDropdownItem @click="removeData(data.item.uuid)">
                      <v-icon name="trash" class="mr-1 v-mid"/> Delete
                    </CDropdownItem>
                  </CDropdown>
                </td>
            </template>
            </CDataTable>
        </CCardBody>
      </CCard>
    </CRow>
    <CModal
      title="Modal Delete"
      :show.sync="modalDelete"
      size="sm"
    >
      <span>Are you sure remove this data ?</span>
      <template #header>
        <h6 class="modal-title">Remove Data</h6>
        <CButtonClose @click="modalDelete = false"/>
      </template>
      <template #footer>
        <CButton @click="modalDelete = false" color="light" class="text-left">Cancel</CButton>
        <CButton @click="destroyData(delId)" color="danger">Yes</CButton>
      </template>
    </CModal>
    <CModal
      title="Modal Form"
      :show.sync="modalForm"
      :closeOnBackdrop="false"
      size="xl"
    >
      <CCol v-if="isMLoading" xl="12" style="text-align: center;">
        <CSpinner
          color="info"
        />
      </CCol>
      <CForm v-if="!isMLoading">
        <CInput
          label="Code"
          :description="errors.code"
          placeholder="Please fill position code"
          type="text"
          vertical
          :readonly="frmaction=='view'?true:false"
          v-model="form.code"
        />
        <CInput
          label="Name"
          :description="errors.name"
          placeholder="Please fill position name"
          type="text"
          vertical
          :readonly="frmaction=='view'?true:false"
          v-model="form.name"
        />
        <div class="form-group" role="group" v-if="frmId && !actived">
          <label>Activate</label>
          <v-select 
              :reduce="activates => activates.value"
              v-model="form.is_active"
              :options="activates"
              placeholder="Please select activate"
              :disabled="frmaction=='view'?true:false"
          />
          <small class="form-text text-muted w-100">{{errors.is_active}}</small>
        </div>
      </CForm>
      <template #header>
        <h6 class="modal-title">{{frmId==''?'Add':frmaction!='view'?'Edit':'Show'}} Data</h6>
        <CButtonClose @click="modalForm = false"/>
      </template>
      <template #footer>
        <CButton @click="modalForm = false" color="light" class="text-left">Cancel</CButton>
        <CButton v-if="frmaction=='view'?false:true" @click="frmaction=='add'?postData():updateData(frmId)" color="info">{{frmId==''?'Save':'Update'}}</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Position',
  components: {
  },
  props: {
      fields: {
        type: Array,
        default () {
            return [
                { key: 'number', label: '#' },
                { key: 'code', label: 'Code' },
                { key: 'name', label: 'Name' },
                { key: 'actions', label: '' }
            ]
        }
      },
  },
  data () {
    return {
        isLoading: false, isMLoading: false,
        modalForm: false, modalDelete: false,
        find: '',
        page: '',
        itemperpage: 10,
        position: [],
        activates: [
          { value: 1, label: 'Active' },
          { value: 0, label: 'Disactive' }
        ],
        delId: '', frmId: '', frmaction: 'view',
        actived: '',
        form: {
          uuid: '',
          code: '',
          name: '',
          is_active: ''
        },
        errors: {
          code: '',
          name: '',
          is_active: ''
        }
    }
  },
  watch: {
    modalForm: {
      handler: function (val, oldVal) {
        if(!val){
          this.frmId = '', this.frmaction = 'view', this.actived = '',
          this.form = { uuid: '', code: '', name: '', is_active: '' }
          this.errors = { code: '', name: '', is_active: '' }
        }
      }
    },
    modalDelete: {
      handler: function (val, oldVal) {
        if(!val){
          this.delId = ''
        }
      }
    }
  },
  created() {
    this.reRender()
  },
  methods: {
    ...mapActions('position', ['indexPST', 'storePST', 'updatePST', 'showPST', 'destroyPST']),
    reRender(){
      this.isLoading = true
      this.position = []
      this.indexPST().then((response) => {
        this.position = response.length > 0 ? response : []
        this.isLoading = false
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error })
        this.isLoading = false
      })
    },
    viewData(uuid){
      this.frmaction = 'view'
      this.frmId = uuid
      this.modalForm = true
      this.showData(uuid)
    },
    addData(){
      this.frmaction = 'add'
      this.modalForm = true
    },
    editData(uuid){
      this.frmaction = 'edit'
      this.frmId = uuid
      this.modalForm = true
      this.showData(uuid)
    },
    removeData(uuid){
      this.modalDelete = true
      this.delId = uuid
    },
    showData(uuid){
      this.isMLoading = true
      this.showPST(uuid).then((response) => {
        this.form = response
        this.actived = response.is_active
        this.isMLoading = false
      }).catch((error) => {
        this.isMLoading = false
      })
    },
    postData(){
      this.isMLoading = true
      this.storePST(this.form).then((response) => {
        this.reRender()
        this.isMLoading = false
        this.modalForm = false
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error.statusText })
        this.getError(error.data)
        this.isMLoading = false
      })
    },
    updateData(uuid){
      this.isMLoading = true
      this.updatePST(this.form).then((response) => {
        this.reRender()
        this.isMLoading = false
        this.modalForm = false
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error.statusText })
        this.getError(error.data)
        this.isMLoading = false
      })
    },
    destroyData(uuid){
      this.modalDelete = false
      this.destroyPST(uuid).then((response) => {
        this.reRender()
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error })
        this.isLoading = false
      })
    },
    getError(data){
      if(typeof(data) == 'object' && data){ if(data.errors) { 
        this.errors.code = data.errors.code ? data.errors.code[0] : ''
        this.errors.name = data.errors.name ? data.errors.name[0] : ''
        this.errors.is_active = data.errors.is_active ? data.errors.is_active[0] : ''
      }}
    }
  }
}
</script>